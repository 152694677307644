import { FullScreenLayout } from "./layout/app-layout/full-screen-layout/full-screen-layout.component";
import { Page404Component } from "./authentication/page404/page404.component";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guard/auth.guard";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard-old/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./profile/profile.module").then((m) => m.ProdileModule),
      },
      {
        path: "steps",
        loadChildren: () =>
          import("./intellisteps/intellisteps.module").then(
            (m) => m.IntelliStepsModule
          ),
      },
      {
        path: "screening",
        loadChildren: () =>
          import("./screening/screening.module").then((m) => m.ScreeningModule),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./inventory/inventory.module").then((m) => m.InventoryModule),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "dashboard",
    component: FullScreenLayout,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./dashboard/screening-dashboard.module").then(
        (m) => m.ScreeningDashboardModule
      ),
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
