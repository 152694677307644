<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar">
    <!-- Menu -->
    <div class="menu">
      <ul
        class="list"
        [ngStyle]="{
          position: 'relative',
          'max-height': listMaxHeight + 'px',
          'max-width': listMaxWidth + 'px'
        }"
        [perfectScrollbar]
      >
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class="image">
              <img
                src="assets/images/usrbig.jpg"
                class="img-circle user-img-circle"
                alt="User Image"
              />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name">{{ loginUser.fullName }}</div>
          </div>
          <hr />
        </li>
        <!-- Top Most level menu -->

        <li
          [class.active]="
            level1Menu === sidebarItem.moduleName &&
            sidebarItem.submenu.length != 0
          "
          *ngFor="let sidebarItem of sidebarItems"
          [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'"
        >
          <ng-container
            *ngIf="
              checkUserRoles(sidebarItem.roles) &&
              isSelectedModule(sidebarItem.moduleName)
            "
          >
            <div class="header" *ngIf="sidebarItem.groupTitle === true">
              {{ sidebarItem.title }}
            </div>
            <a
              [title]="sidebarItem.title"
              [routerLink]="
                sidebarItem.class === '' ? [sidebarItem.path] : null
              "
              *ngIf="!sidebarItem.groupTitle"
              [ngClass]="[sidebarItem.class]"
              (click)="callLevel1Toggle($event, sidebarItem.moduleName)"
              class="menu-top"
            >
              <i [ngClass]="[sidebarItem.iconType]">{{ sidebarItem.icon }}</i>
              <span class="hide-menu">{{ sidebarItem.title }} </span>
            </a>
            <!-- First level menu -->
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <li
                [ngClass]="
                  level2Menu === sidebarSubItem.moduleName ? 'activeSub' : ''
                "
                *ngFor="let sidebarSubItem of sidebarItem.submenu"
                [routerLinkActive]="
                  sidebarSubItem.submenu.length > 0 ? '' : 'active'
                "
              >
                <ng-container *ngIf="checkUserRoles(sidebarSubItem.roles)">
                  <a
                    [routerLink]="
                      sidebarSubItem.submenu.length > 0
                        ? null
                        : [sidebarSubItem.path]
                    "
                    (click)="
                      callLevel2Toggle($event, sidebarSubItem.moduleName)
                    "
                    [ngClass]="[sidebarSubItem.class]"
                  >
                    {{ sidebarSubItem.title }}
                  </a>
                  <!-- Second level menu -->
                  <ul
                    class="ml-menu-2"
                    *ngIf="sidebarSubItem.submenu.length > 0"
                  >
                    <li
                      *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                      [ngClass]="
                        level3Menu === sidebarSubsubItem.moduleName
                          ? 'activeSubSub'
                          : ''
                      "
                      [routerLinkActive]="
                        sidebarSubsubItem.submenu.length > 0 ? '' : 'active'
                      "
                    >
                      <ng-container
                        *ngIf="checkUserRoles(sidebarSubsubItem.roles)"
                      >
                        <a
                          [routerLink]="
                            sidebarSubsubItem.submenu.length > 0
                              ? null
                              : [sidebarSubsubItem.path]
                          "
                          (click)="
                            callLevel3Toggle(
                              $event,
                              sidebarSubsubItem.moduleName
                            )
                          "
                          [ngClass]="[sidebarSubsubItem.class]"
                        >
                          {{ sidebarSubsubItem.title }}
                        </a>
                      </ng-container>
                    </li>
                  </ul>
                </ng-container>
              </li>
            </ul>
          </ng-container>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
