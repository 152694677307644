import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { UtilService } from 'src/app/shared/services/util.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private utility: UtilService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let apiUrl = request.url;
    if(request.method == 'POST') {
      const temp = apiUrl.split('/');
      const id = temp[temp.length - 1];
      const body = JSON.stringify(request.body)
      apiUrl = id == 'list' ? request.url + body : request.url;
    }
    let cachedResponse: any;
    if(!!this.utility.getLocalItem(apiUrl) && ( request.method == 'GET' || request.method == 'POST') ) {
      cachedResponse = this.utility.getLocalItem(apiUrl);
    }

    // If the record is found in localStorage, return it without making the API call
    if (cachedResponse) {
      const response = new HttpResponse({
        body: cachedResponse,
        status: 200
      });

      return of(response);
    }

    return next.handle(request).pipe(
      tap(event => {
        if(request.method == 'PUT' || request.method == 'DELETE') {
            let url = request.url;
            const temp = url.split('/');
            const id = temp[temp.length - 3] + '/' + temp[temp.length - 2];
            this.utility.caching_records = this.utility?.caching_records?.filter(item => {
              if(item.key.includes(id)) return false;
              return true;
            });
        }
        // Check if the event is an HTTP response
        if(request.method != 'GET' && request.method != 'POST') return;
        if (event instanceof HttpResponse) {
          let url = request.url;
          const temp = url.split('/');
          const id = temp[temp.length - 1];
          if(request.method == 'POST') {
            const body = JSON.stringify(request.body)
            url = id == 'list' ? request.url + body : request.url;

            if(id != 'list') {
              this.utility.caching_records = this.utility?.caching_records?.filter(item => {
                if(item.key.includes(request.url)) return false;
                return true;
              });
              return;
            }
          }

          if(this.checkNonCachingUrls(url)) return;
          if(id != 'list' &&  request.method == 'POST') return;
          this.utility.setLocalItem(url,  event.body);
        }
      })
    );
  }

  checkNonCachingUrls(url) {
    return !!this.utility.notCachingUrls.find((ele: any) => url.includes(ele));
  }
}
