import { Injectable } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(private snackBar: MatSnackBar, private toastr: ToastrService) {}

  caching_records: any;
  notCachingUrls: any = [
    "v1/api/users/surgeons", "/v1/surgeons/all", "/v1/api/orders", "/v1/api/order-change-logs", "/v1/api/patients", "/v1/api/queue", "/v1/api/station-groups", "/v1/api/stations", "/v1/api/login", "api/users/doctors", "v1/api/companies", "v1/api/auth/login", "/v1/api/users", "/v1/cases", "/v1/case-cards", "/v1/categories", "/v1/consignment-orders", "/v1/api/messagings", "/v1/facilities", "/v1/items", "/v1/locations", "/v1/media", "/v1/procedure-cards", "/v1/procedures", "/v1/sub-locations", "/v1/surgeons", "/v1/units", "/v1/api/services/all", "/v1/api/dashboard"
  ]

  public prepareURL(
    baseURL: string,
    page: number,
    size: number,
    sortBy: string,
    direction: string,
    criteria: any
  ): string {
    let url = baseURL + "?page=" + page + "&size=" + size + "&sort=" + sortBy + "," + direction;

    Object.keys(criteria).forEach((key: string) => {
      let value = criteria[key];
      let k = key;

      if (value instanceof Array && value != null && value.length > 0) {
        value.forEach((element: string) => {
          url += "&" + k + "=" + element;
        });
      }
       else if (value !== undefined && value != null && value instanceof moment) {
        let a = moment(value).format("yyyy-MM-DD");
        url += "&" + k + "=" + a;
      }
      else if (value !== undefined && value != null && value instanceof Date) {
        console.log(value);
        url += "&" + k + "=" + this.formatDate(value);
      } else if (value !== undefined && value != null && value.length > 0) {
        url += "&" + k + "=" + value;
      } else if (value !== undefined && value > 0) {
        url += "&" + k + "=" + value;
      }
    });
    return url;
  }

  setLocalItem(key: string, value) {
    let apiDataArray = this.caching_records || [];
    apiDataArray.push({ key: key, value: value });
    this.caching_records = apiDataArray;
    console.log('caching_records', this.caching_records);
  }

  getLocalItem(key: string) {
    const apiDataArray = this.caching_records || [];
    if(!apiDataArray.length) return false;
    const record = apiDataArray.find((ele: any) => ele.key == key)
    try {
        return record.value;
    } catch (e) {
        return record;
    }
  }

  formatDate(date: Date): string {
    return moment(date).format("yyyy-MM-DD");
  }

  successSwalFire(title: string = '', showCancelButton: boolean = false, confirmButtonColor: string = '', cancelButtonColor: string = '', confirmButtonText: string = '', html: string = '', cancelButtonText: string = '') {
    return Swal.fire({
      title: title,
      icon: 'success',
      showCancelButton: showCancelButton,
      confirmButtonColor: confirmButtonColor,
      cancelButtonColor: cancelButtonColor,
      confirmButtonText: confirmButtonText,
      html: html,
      cancelButtonText: cancelButtonText,
    })
  }

  showSuccessMessage(message: string) {
    this.toastr.success(message || 'Action successfully completed', 'Success');
  }

  showErrorMessage(error: any) {
    this.toastr.error(error?.message || 'Something went wrong', 'Error');
  }
}
