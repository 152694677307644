import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: any[], property?: string): any[] {
    if (!value || !Array.isArray(value)) {
      return value;
    }

    return value.slice().sort((a, b) => {
      const itemA = property ? a[property] : a;
      const itemB = property ? b[property] : b;
      return itemA.localeCompare(itemB);
    });
  }

}
